import Breadcrumb from "../../component/breadcrumb";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CheckLottery from "../../component/check-lottery";
import { useEffect, useMemo, useState } from "react";
import {
  ILotterySetSummary,
  IPeriodLottery,
  IPeriodLotteryWithMainResult,
} from "../../interface/lottery";
import useSnackbar from "../../utils/snackbar";
import {
  filterPeriodLotteryApi,
  getLotterySetSummaryByPeriodIdApi,
  getPeriodLotteryActiveApi,
  getPeriodLotteryByPeriodYearApi,
} from "../../api/lottery";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { getOrderSummaryDashboardApi } from "../../api/order";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const dispatch = useAppDispatch();
  const activePeriod = useAppSelector((state) => state.auth.activePeriod);
  const listYear = useMemo(() => {
    if (activePeriod) {
      return Array.from({ length: 11 }).map(
        (_, i) => activePeriod.period.year - i
      );
    }
    return Array.from({ length: 11 }).map((_, i) => moment().year() - i);
  }, [activePeriod]);
  const [selectedYear, setSelectedYear] = useState<number>(
    activePeriod?.period.year ?? moment().year()
  );
  const [loading, setLoading] = useState(false);
  const [activeOrLastPeriod, setActiveOrLastPeriod] =
    useState<IPeriodLottery | null>(activePeriod);
  const [summaryPeriod, setSummaryPeriod] = useState<ILotterySetSummary>();
  const [periodLottery, setPeriodLottery] = useState<
    IPeriodLotteryWithMainResult[]
  >([]);
  const [selectedPeriod, setSelectedPeriod] =
    useState<IPeriodLotteryWithMainResult>();
  const [chart, setChart] = useState<
    {
      date: string;
      totalOrder: number;
      totalAmount: number;
    }[]
  >();

  const initData = async () => {
    try {
      setLoading(true);
      let { data } = await getPeriodLotteryActiveApi();
      if (!data) {
        const period = await filterPeriodLotteryApi({ page: 1, limit: 1 });
        data = period.data.results?.length > 0 ? period.data.results[0] : null;
      }
      if (!!data) {
        const [summary] = await Promise.all([
          getLotterySetSummaryByPeriodIdApi(data.id),
        ]);
        setSummaryPeriod(summary.data);
      }
      setActiveOrLastPeriod(data);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const getPeriodLotteryByPeriodYear = async (year: number) => {
    try {
      const { data } = await getPeriodLotteryByPeriodYearApi(year);
      setPeriodLottery(data ?? []);
      if ((data ?? []).length > 0) {
        setSelectedPeriod(data.find((e) => e.status === 1) ?? data[0]);
        await getOrderSummaryDashboard(
          (data.find((e) => e.status === 1) ?? data[0]).id
        );
      } else {
        setSelectedPeriod(undefined);
      }
    } catch (e: any) {
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const getOrderSummaryDashboard = async (periodId: string) => {
    try {
      const { data } = await getOrderSummaryDashboardApi(periodId);
      setChart(data ?? []);
    } catch (e: any) {
      throw e;
    }
  };

  useEffect(() => {
    if (!!selectedPeriod) {
      getOrderSummaryDashboard(selectedPeriod!.id);
    }
    // eslint-disable-next-line
  }, [selectedPeriod]);

  useEffect(() => {
    getPeriodLotteryByPeriodYear(selectedYear);
    // eslint-disable-next-line
  }, [selectedYear]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Breadcrumb
        title={"หน้าหลัก"}
        children={[{ title: "Home", link: "/" }, { title: "Dashboard" }]}
      />

      <CheckLottery />

      <div className="row mb-2 mt-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4 border-bottom">
                <div className="col-lg-4 mb-2">
                  <span className="h6 mr-2">{`งวดวันที่ ${
                    activeOrLastPeriod?.period.day ?? ""
                  } ${
                    !!activeOrLastPeriod
                      ? moment.months(activeOrLastPeriod!.period.month - 1)
                      : ""
                  } ${
                    !!activeOrLastPeriod
                      ? activeOrLastPeriod!.period.year + 543
                      : ""
                  }`}</span>
                  <span className="text-muted tx-12">
                    {moment(activeOrLastPeriod?.openDatetime).format(
                      "DD/MM/yyyy hh:mm:ss"
                    )}{" "}
                    -{" "}
                    {moment(activeOrLastPeriod?.closeDatetime).format(
                      "DD/MM/yyyy hh:mm:ss"
                    )}
                  </span>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-auto d-none d-md-flex">
                      <div className="btn btn-primary ml-2 mt-1">
                        <h2 className="m-0">
                          {activeOrLastPeriod?.period.day ?? ""}
                        </h2>
                        <h4 className="m-0">
                          {!!activeOrLastPeriod
                            ? moment.monthsShort(
                                activeOrLastPeriod!.period.month - 1
                              )
                            : ""}
                        </h4>
                      </div>
                    </div>
                    <div className="col-md border-right">
                      <div className="d-flex align-items-center justify-content-md-center mb-2 mb-md-0">
                        <i className="mdi mdi-flag-variant-outline icon-md mr-3 text-info mt-2"></i>
                        <div>
                          <p className="mb-1 tx-12 text-muted">
                            ล็อตเตอรี่ทั้งหมด
                          </p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2 font-weight-bold-">
                              {activeOrLastPeriod?.totalLottery?.toLocaleString(
                                "en-US"
                              ) ?? 0}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md border-right">
                      <div className="d-flex align-items-center justify-content-md-center mb-2 mb-md-0">
                        <i className="mdi mdi-trophy-outline icon-md mr-3 text-success mt-2"></i>
                        <div>
                          <p className="mb-1 tx-12 text-muted">ขายไปทั้งหมด</p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2 font-weight-bold- text-success">
                              {summaryPeriod?.sold?.toLocaleString("en-US") ??
                                0}
                            </h3>
                            <p className="tx-12 mb-0 mt-3 text-muted">
                              {`${(
                                ((summaryPeriod?.sold ?? 0) * 100) /
                                (activeOrLastPeriod?.totalLottery === 0
                                  ? 1
                                  : activeOrLastPeriod?.totalLottery ?? 1)
                              ).toFixed(2)}%`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md border-right">
                      <div className="d-flex align-items-center justify-content-md-center mb-2 mb-md-0">
                        <i className="mdi mdi-timer icon-md mr-3 text-warning mt-2"></i>
                        <div>
                          <p className="mb-1 tx-12 text-muted">
                            ล็อตเตอรี่ติดจอง
                          </p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2 font-weight-bold- text-warning">
                              {summaryPeriod?.reserved?.toLocaleString(
                                "en-US"
                              ) ?? 0}
                            </h3>
                            <p className="tx-12 mb-0 mt-3 text-muted">
                              {`${(
                                ((summaryPeriod?.reserved ?? 0) * 100) /
                                (activeOrLastPeriod?.totalLottery === 0
                                  ? 1
                                  : activeOrLastPeriod?.totalLottery ?? 1)
                              ).toFixed(2)}%`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="d-flex align-items-center justify-content-md-center mb-2 mb-md-0">
                        <i className="mdi mdi-package-variant-closed icon-md mr-3 text-danger mt-2"></i>
                        <div>
                          <p className="mb-1 tx-12 text-muted">
                            ล็อตเตอรี่คงเหลือ
                          </p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2 font-weight-bold- text-danger">
                              {(
                                (activeOrLastPeriod?.totalLottery ?? 0) -
                                (summaryPeriod?.sold ?? 0)
                              ).toLocaleString("en-US")}
                            </h3>
                            <p className="tx-12 mb-0 text-muted mt-3">
                              {`${(
                                (((activeOrLastPeriod?.totalLottery ?? 0) -
                                  (summaryPeriod?.sold ?? 0)) *
                                  100) /
                                (activeOrLastPeriod?.totalLottery === 0
                                  ? 1
                                  : activeOrLastPeriod?.totalLottery ?? 1)
                              ).toFixed(2)}%`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 border-top p-2">
                  {summaryPeriod?.sets
                    .sort((i, j) => i.set - j.set)
                    .map((e) => (
                      <span
                        key={e.set}
                        className="badge badge-outline-primary badge-pill m-1"
                      >
                        ชุด {e.set} ใบ {e.total.toLocaleString("en-US")}ชุด
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="row mt-2">
                <div className="col-auto mr-4">
                  <h2 className="mb-1 font-weight-medium font-primary">
                    {(
                      chart?.reduce(
                        (sum, e: any) => sum + (e.totalAmount as number),
                        0
                      ) ?? 0
                    ).toLocaleString("en-US")}
                  </h2>
                  <p className="text-muted mb-0 tx-12">ยอดขายทั้งหมด</p>
                </div>

                <div className="col-auto mr-4">
                  <h2
                    className="mb-1 font-weight-medium "
                    style={{ color: "#FFB93E" }}
                  >
                    {(
                      chart?.reduce(
                        (sum, e: any) => sum + (e.totalOrder as number),
                        0
                      ) ?? 0
                    ).toLocaleString("en-US")}
                  </h2>
                  <p className="text-muted mb-0 tx-12">จำนวนออเดอร์</p>
                </div>
                <div className="col-lg-3 p-0 ml-auto ">
                  <div
                    className="d-flex flex-column flex-md-row"
                    style={{
                      gap: ".5rem",
                      paddingLeft: "0.625rem",
                      paddingRight: "0.625rem",
                    }}
                  >
                    <div className="form-group" style={{ minWidth: 100 }}>
                      <select
                        className="form-control"
                        value={selectedYear}
                        onChange={(e) => {
                          setSelectedYear(+e.target.value);
                        }}
                      >
                        {listYear.map((year) => (
                          <option key={year} value={year}>
                            {year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group w-100">
                      <select
                        className="form-control"
                        value={selectedPeriod?.id}
                        onChange={(e) => {
                          setSelectedPeriod(
                            periodLottery.find((ee) => ee.id === e.target.value)
                          );
                        }}
                      >
                        {periodLottery.map((val) => (
                          <option key={val.id} value={val.id}>
                            งวดวันที่ {val.period.day}{" "}
                            {moment.months(val.period.month - 1)}{" "}
                            {val.period.year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="container"
                className="mb-4"
                style={{
                  width: "100%",
                  height: 350,
                }}
              >
                <Bar
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "top" as const,
                      },
                    },
                    scales: {},
                  }}
                  data={{
                    labels: chart?.map((e: any) => e.date as string) ?? [],
                    datasets: [
                      {
                        label: "ยอดขายทั้งหมด",
                        data:
                          chart?.map((e: any) => e.totalAmount as number) ?? [],
                        backgroundColor: "#C1272D",
                        borderColor: "#C1272D ",
                      },
                      {
                        label: "จำนวนออเดอร์",
                        data:
                          chart?.map((e: any) => e.totalOrder as number) ?? [],
                        backgroundColor: "#FFB93E",
                        borderColor: "#FFB93E ",
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

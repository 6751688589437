import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "../page/auth/sign-in";
import RenderNews from "../page/news/news";

const Page = () => {
  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <Routes>
          <Route
            path={`${process.env.PUBLIC_URL}/login`}
            element={<SignIn />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/news/read/:id`}
            element={<RenderNews />}
          />
          <Route
            path="*"
            element={
              <Navigate to={`${process.env.PUBLIC_URL}/login`} replace />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Page;
